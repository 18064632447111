import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faNotesMedical, faSchool, faCoins, faMicrochip, faInfoCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';
import './Industry.css'; // Import custom CSS file for styling

const Industry = () => {
  return (
    <div className='industry-container'>
      <div className="text-dark justify-content-center p-5">
        <div className="d-flex justify-content-center align-items-center flex-column col-md-5 mx-auto">
          <h5 className="fs-1" style={{ color: "black" }}><b>Industries We Serve</b></h5>
        </div>
      </div>
      <div className="industry-icons">
        <div className="industry-icon">
          <FontAwesomeIcon icon={faBuilding} />
          <p>Corporate</p>
        </div>

        <div className="industry-icon">
          <FontAwesomeIcon icon={faNotesMedical} />
          <p>Healthcare</p>
        </div>

        <div className="industry-icon">
          <FontAwesomeIcon icon={faSchool} />
          <p>Education</p>
        </div>

        <div className="industry-icon">
          <FontAwesomeIcon icon={faCoins} />
          <p>Financial Service</p>
        </div>

        <div className="industry-icon">
          <FontAwesomeIcon icon={faMicrochip} />
          <p>Information Technology</p>
        </div>

        <div className="industry-icon">
          <FontAwesomeIcon icon={faInfoCircle} />
          <p>Retail</p>
        </div>

        <div className="industry-icon">
          <FontAwesomeIcon icon={faHandshake} />
          <p>Hospitality</p>
        </div>
      </div>
    </div>
  );
};

export default Industry;
