import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll'; // Import Link from react-scroll
import logo from './Logo2.png';

const Footer = () => {
    return (
        <div>
            <footer className="text-center text-black" style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.8)" }}>

                <section>
                    <div className="container text-center text-md-start mt-5 p-4">

                        <div className="row mt-3">

                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                {/* Corrected logo display */}
                                <img src={logo} alt="AuthBox Logo" className="img-fluid pb-2" />

                                <p>
                                    Discover the Power of Accurate and Reliable Background Checks with Authbox, India's
                                    Premier Background Verification and Risk Assessment Firm, Serving 200+ Satisfied Clients
                                </p>
                                <div>
                                    <a href="https://www.facebook.com/" className="me-4 link-secondary text-black">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a href="https://www.instagram.com/" className="me-4 link-secondary text-black">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a href="https://www.linkedin.com/" className="me-4 link-secondary text-black">
                                        <i className="fab fa-linkedin"></i>
                                    </a>
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h5 className="text-uppercase fw-bold mb-4">OUR SERVICES</h5>
                                <p>Address Verification</p>
                                <p>Employment Verification</p>
                                <p>Education Verification</p>
                                <p>Court record Verification</p>
                                <p>ID Verification</p>
                                <p>Global Database Verification</p>
                            </div>

                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h5 className="text-uppercase fw-bold mb-4">QUICK LINKS</h5>
                                <p><ScrollLink to="about" smooth={true} duration={100} className="text-reset text-black">Home</ScrollLink></p>
                                <p><ScrollLink to="about" smooth={true} duration={100} className="text-reset text-black">About Us</ScrollLink></p>
                                <p><ScrollLink to="whyis" smooth={true} duration={100} className="text-reset text-black">Why Choose Us</ScrollLink></p>
                                <p><ScrollLink to="service" smooth={true} duration={100} className="text-reset text-black">Services</ScrollLink></p>
                                <p><ScrollLink to="testimonial" smooth={true} duration={100} className="text-reset text-black">Clients</ScrollLink></p>
                                <p><ScrollLink to="contact" smooth={true} duration={100} className="text-reset text-black">Contact Us</ScrollLink></p>
                            </div>

                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h5 className="text-uppercase fw-bold mb-4">CONTACT INFO</h5>
                                <p><i className="fas fa-home me-3"></i><span className="fw-bold">710/40, Sector-40, Gurgaon, Haryana – 122009</span></p>
                                <p><i className="fas fa-phone me-3"></i><span className="fw-bol">+91-9721860114</span></p>
                                <p><i className="fas fa-phone me-3"></i><span className="fw-bol">+91-9990544110</span></p>
                                <p><i className="fas fa-phone me-3"></i><span className="fw-bol">+91-9990544669</span></p>
                                <p><i className="fas fa-phone me-3"></i><span className="fw-bol">+91-8651692595</span></p>
                                <p>
                                    <i className="fas fa-envelope me-3"></i>
                                    <a href="mailto:info@authbox.in" className="fw-bold">info@authbox.in</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="text-center p-4" style={{ backgroundColor: "rgba(0, 0, 0, 0.025)" }}>
                    <span className='px-5'>© 2024 AuthBox India Private Limited, All right reserved</span> <span className='px-5'>Designed By : DigiNXT Media</span>
                </div>

            </footer>
        </div>
    );
}

export default Footer;
