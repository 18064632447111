import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonial.css';

const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="testimonials-container text-center p-4">
            <div className="container">
                <h5 className="fs-1 mb-4" style={{ color: "black" }}><b>Testimonial</b></h5>
                <Slider {...settings}>
                    <div className="testimonial-card border-light bg-light text-center p-2">
                        <i className="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                        <div className="card-body blockquote d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                            <p className="card-text p-2 d-flex justify-content-center align-items-center">I'm awestruck by Authbox India's background check services. Their reports are detailed and thorough, with accurate and in-depth investigations. Authbox India has set a new standard for background verification. I highly recommend their services.</p>
                        </div>
                        <footer className="blockquote-footer"><cite title="Source Title">Himanshu Singh (TechNXT Solutions)</cite></footer>
                    </div>
                    <div className="testimonial-card border-light bg-light text-center p-2">
                        <i className="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                        <div className="card-body blockquote d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                            <p className="card-text p-2">Authbox India's background verification services are outstanding. Their checks are highly accurate, and their prices are the lowest in the market. I've used other providers, but none match Authbox's level of detail and thoroughness.</p>
                        </div>
                        <footer className="blockquote-footer"><cite title="Source Title"> Dinesh Yadav (Change Enterprises)</cite></footer>
                    </div>
                    <div className="testimonial-card border-light bg-light text-center p-2">
                        <i className="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                        <div className="card-body blockquote d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                            <p className="card-text p-2">The level of professionalism and dedication exhibited by Authbox is truly unparalleled and unbeatable, and I'm immensely grateful to have found a partner I can trust implicitly to consistently deliver exceptional results every single time.</p>
                        </div>
                        <footer className="blockquote-footer"><cite title="Source Title"> Karun Vishal  (Selorix India Private Limited)</cite></footer>
                    </div>
                    <div className="testimonial-card border-light bg-light text-center p-2">
                        <i className="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                        <div className="card-body blockquote d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                            <p className="card-text p-2">Authbox's profound expertise and skilled service in conducting thorough background checks has been absolutely invaluable in guaranteeing a secure and fully compliant work environment for our organization and its employees.</p>
                        </div>
                        <footer className="blockquote-footer"><cite title="Source Title"> Pankaj Singh  (Blue Epic Technologies)</cite></footer>
                    </div>
                    <div className="testimonial-card border-light bg-light text-center p-2">
                        <i className="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                        <div className="card-body blockquote d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                            <p className="card-text p-2">Authbox, with their prompt and dependable background verification services, played a pivotal role in optimizing and refining our hiring procedures, ensuring efficiency and reliability throughout the entire process. They are amazing.</p>
                        </div>
                        <footer className="blockquote-footer"><cite title="Source Title"> Naveen Kumar (R3R Buildcon Private Limited)</cite></footer>
                    </div>
                    <div className="testimonial-card border-light bg-light text-center p-2">
                        <i className="fa fa-quote-left fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                        <div className="card-body blockquote d-flex justify-content-center align-items-center" style={{ height: "250px" }}>
                            <p className="card-text p-2">Authbox India surpasses all expectations in the realm of reporting and analytics, setting a new benchmark with its innovative platform capabilities. Continually astounded by its capabilities, I find myself in awe of the vast potential and possibilities it offers.</p>
                        </div>
                        <footer className="blockquote-footer"><cite title="Source Title"> Dimple Juneja  (Real Concept)</cite></footer>
                    </div>
                </Slider>
            </div>
        </div>
    );
}

export default Testimonial;
