import React from 'react'
import img1 from './Basics21.png'
import img2 from './BlueEpic.png'
import img3 from './change_enterprises_private_limited_logo.jpg'
import img4 from './Real-Concept.png'
import img5 from './Selorix-India.png'
import img6 from './TechNXT-Logo.png'
import './Client.css'
const Clients = () => {
  return (
    <div className='abc'>
        
         <img src={img1} alt="AuthBox Logo" className="img-fluid p-4" style={{width:"15%"}} />
         <img src={img2} alt="AuthBox Logo" className="img-fluid p-4" style={{width:"15%"}} />
         <img src={img3} alt="AuthBox Logo" className="img-fluid p-4" style={{width:"15%"}} />
         <img src={img4} alt="AuthBox Logo" className="img-fluid p-4" style={{width:"15%"}} />
         <img src={img5} alt="AuthBox Logo" className="img-fluid p-4" style={{width:"15%"}} />
         <img src={img6} alt="AuthBox Logo" className="img-fluid p-4" style={{width:"15%"}} />
    </div>
  )
}

export default Clients