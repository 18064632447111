import React from 'react';
import video from './vidio.mp4';
import About from './About';
import Service from './Service';
import Testimonial from './Testimonial';
import Industry from './Industry';
import Whyis from './Whyis';
import { Element } from 'react-scroll'; // Import Element from react-scroll
import Carousal from './Carousal';
import Form from './Form';
import FormVideo from './FormVideo';
import Clients from './Clients';

const Home = () => {
    return (
        <div>
            <div className='pb-4'>
                <div className="card text-dark text-center">
                    <video autoPlay loop muted id="video" style={{ width: "100%", filter: "brightness(30%)" }}>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className="card-img-overlay d-flex justify-content-center align-items-center flex-column">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5">
                                <h5 className="card-title fs-1" style={{ color: "white" }}>
                                    <b style={{ fontSize: "70px" }}>Auth<span style={{ color: "#FF5F00" }}>Box</span></b>
                                    <p style={{textAlign:"left"}}> Your Trusted Partner for Comprehensive Background Verification Solutions.</p>
                                    <p style={{textAlign:"left" , fontSize:"30px"}} className='pt-2'>Discover the Power of Accurate and Reliable Background Checks with Authbox, India's Premier</p>
                                </h5>
                            </div>
                            <div className="col-md-5 justify-content-center align-items-center">
                                <FormVideo />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* Wrap each section with Element from react-scroll */}

            <Element name="about">
                <About />
            </Element>
            <Element name="whyis">
                <Whyis />
            </Element>
            <Element name="service">
                <Service />
            </Element>
            <Element name="testimonial">
                <Testimonial />
            </Element>
            <Element name="industry">
                <Industry />
            </Element>

            <Form />
            <Clients/>



        </div>
    );
};

export default Home;
