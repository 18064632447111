import React from 'react'
import './service.css'
const Service = () => {
    return (
        <div className='service p-2'>
            <section className='d-flex justify-content-center align-items-center flex-column'>
                <div className="text-dark justify-content-center p-2">
                    <div className="d-flex justify-content-center align-items-center flex-column col-md-5 mx-auto">
                        <h5 className="fs-1" style={{ color: "black" }}><b>Services</b></h5>
                    </div>
                </div>
                <div class="row">
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/629999446dd7d39b293556ab_id.svg" loading="lazy" alt="" class="allcheckcardimg" />
                            <p className='pr'>
                                ID Verification
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/62999943914a3b35f666aeb9_ads.svg" loading="lazy" alt="" class="allcheckcardimg" />
                            <p className='pr'>
                                Address Verification

                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/629999436dff35356667470b_emp.svg" loading="lazy" alt="" class="allcheckcardimg" />
                            <p className='pr'>
                                Employment Verification
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/62999943f76ad460669c3643_edu.svg" loading="lazy" alt="" class="allcheckcardimg" />
                            <p className='pr'>
                                Education Verification
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/62999941b5e99d1919a0e263_refer.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>

                                Reference Check
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/6299994269aae0a0b2771575_court.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                Court Record Verification
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/6299994127b0444228fc2a0d_global.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                Global database Verification
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/62999941f3f0a52acdccba14_credit.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                Credit Check
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/629999400a1174f81718be79_social.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                Social Media Verification

                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/62999940db8efa6bc301d7e1_drug.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                Drug Test Screening
                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/629999406dd7d35b8a3556a8_passport.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                Passport Verification

                            </p>
                        </div>
                    </div>
                    <div class="columni">
                        <div class="cardi">
                            <img src="https://assets-global.website-files.com/61f1b37129d720e4ab1ce612/629999446dd7d39b293556ab_id.svg" loading="lazy" alt="" class="allcheckcardimg"></img>
                            <p className='pr'>
                                DIN Verification

                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Service