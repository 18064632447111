import React from 'react';
import './Form.css'; // Import CSS file for custom styling

const Form = () => {
  return (
    <div className="container-fluid abcd">
      <div className="row">
        <div className="col-lg-5 detail">
          <div className='detail-content'>
            <h1 className='p-4'>Auth<span style={{color:"#FF5F00"}}>Box</span> Cost Calculator</h1>
            <p style={{ textAlign: "left" , fontSize:"20px" }} className='p-2'>Estimate how much a background check with industry’s lowest TAT and amber rate costs for your organization.</p>
            <p style={{ textAlign: "left" , fontSize:"20px" }} className='p-2'><b>Fill the form to unlock:</b></p>
            <ul>
              <li style={{ textAlign: "left" }} className='p-2'>Instant price estimate based on your organization's BGV needs</li>
              <li style={{ textAlign: "left" }} className='p-2'>Exclusive offers from AuthBox</li>
              <li style={{ textAlign: "left" }} className='p-2'>Free trial packages of AuthBox BGV services</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-7 form p-4">

          <div className="checks-list">
            <div className="form-group" style={{ textAlign: "left" }}>
              <input className="form-check-input" type="checkbox" id="policeCheck" />
              <label className="form-check-label" htmlFor="policeCheck" style={{ paddingLeft: "8px" }}>Police Verification</label>
            </div>
            <div className="form-group" style={{ textAlign: "left" }}>
              <input className="form-check-input" type="checkbox" id="eduCheck" />
              <label className="form-check-label" htmlFor="eduCheck" style={{ paddingLeft: "8px" }}>Education Checks</label>
            </div>
            <div className="form-group" style={{ textAlign: "left" }}>
              <input className="form-check-input" type="checkbox" id="employmentCheck" />
              <label className="form-check-label" htmlFor="employmentCheck" style={{ paddingLeft: "8px" }}>Employment Checks</label>
            </div>
            <div className="form-group" style={{ textAlign: "left" }}>
              <input className="form-check-input" type="checkbox" id="addressCheck" />
              <label className="form-check-label" htmlFor="addressCheck" style={{ paddingLeft: "8px" }}>Address Checks</label>
            </div>
            <div className="form-group" style={{ textAlign: "left" }}>
              <input className="form-check-input" type="checkbox" id="courtCheck" />
              <label className="form-check-label" htmlFor="courtCheck" style={{ paddingLeft: "8px" }}>Court Record Checks</label>
            </div>
          </div>
          <form className="custom-form p-2">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group"  style={{ textAlign: "left" }}>
                  <label htmlFor="inputEmail4" className='p-2'>Average Monthly Hiring</label>
                  <input type="number" className="form-control" id="hiringNumber" placeholder="Average monthly hiring" />
                </div>
                <div className="form-group" style={{ textAlign: "left" }}>
                  <label htmlFor="inputPassword4" className='p-2' >First Name</label>
                  <input type="text" className="form-control" id="firstName" placeholder="First Name" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group" style={{ textAlign: "left" }}>
                  <label htmlFor="inputAddress" className='p-2'>Phone Number</label>
                  <input type="tel" className="form-control" id="inputAddress" placeholder="Phone Number" />
                </div>
                <div className="form-group" style={{ textAlign: "left" }}>
                  <label htmlFor="inputAddress2" className='p-2' >Last Name</label>
                  <input type="text" className="form-control" id="lastName" placeholder="Last name" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group" style={{ textAlign: "left" }}>
                  <label htmlFor="inputCity" className='p-2' >Email</label>
                  <input type="email" className="form-control" id="inputCity" placeholder="Email" />
                </div>
                <div className="form-group" style={{ textAlign: "left" }}>
                  <label htmlFor="inputZip" className='p-2'>Company Name</label>
                  <input type="text" className="form-control" placeholder="Company Name" />
                </div>
              </div>

            </div>
            <button type="submit" className="btn btn-primary custom-btn">Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Form;
