import React from 'react';
import './about.css';
import image from './About.jpg'
const About = () => {
    return (
        <div className="about-container p-5" >
            <div className="image-container">
                <img className="mainImg" src={image} alt="First Image" style={{ height: "500px" }} />
                
            </div>
            <div className="text-container">
                <div className="text-block">
                    <p className="headingText" style={{fontSize:"40px"}}>At Auth<span style={{color:"#FF5F00"}}>Box</span></p>
                    <p className="description" style={{fontSize:"17px", textAlign:"left"}}>
                        We are dedicated to providing comprehensive and reliable background verification services to help individuals and organizations make informed decisions with confidence. 
                        Founded on the principles of integrity, accuracy, and efficiency, we strive to exceed our clients' expectations through our commitment to excellence.
                    </p>
                </div>
                <div className="text-block">
                    <p className="headingText" style={{textAlign:"left"}}>Our Mission</p>
                    <p className="description" style={{fontSize:"17px", textAlign:"left"}}>
                        We are dedicated to providing comprehensive and reliable background verification services to help individuals and organizations make informed decisions with confidence. 
                        Founded on the principles of integrity, accuracy, and efficiency, we strive to exceed our clients' expectations through our commitment to excellence.
                    </p>
                </div>
                <div className="text-block">
                    <p className="headingText"  style={{textAlign:"left"}}>Our Commitment</p>
                    <p className="description" style={{fontSize:"17px", textAlign:"left"}}>
                        We are dedicated to providing comprehensive and reliable background verification services to help individuals and organizations make informed decisions with confidence. 
                        Founded on the principles of integrity, accuracy, and efficiency, we strive to exceed our clients' expectations through our commitment to excellence.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
