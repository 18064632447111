import React from 'react'

const Whyis = () => {
  return (
    <div>
      <div className='p-4'>
                <div className="p-4">
                    <div className="text-dark justify-content-center p-5">
                        <div className="d-flex justify-content-center align-items-center flex-column col-md-5 mx-auto">
                            <h5 className="fs-1" style={{ color: "black" }}><b>Why Choose us</b></h5>
                        </div>
                    </div>



                    <div>



                        <div class="testimonials text-center">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-6 col-lg-3">
                                        <div class="card border-light bg-transparent text-center">
                                            <i class="fa fa-solid fa-award fa-3x card-img-top rounded-circle" aria-hidden="true" ></i>
                                            <div class="card-body blockquote">
                                                <h3>Expertise</h3>
                                            <p class="card-text" style={{fontSize:"20px"}}>Our team comprises seasoned professionals with extensive experience in background
                                                    verification, equipped with the skills to handle complex cases.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3">
                                        <div class="card border-light bg-transparent text-center">
                                            <i class="fa fa-solid fa-user-tie fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                            <div class="card-body blockquote">
                                                <h3>Compliance</h3>
                                                <p class="card-text" style={{fontSize:"20px"}}>We stay up-to-date with all relevant laws, regulations, and industry best practices to
                                                    ensure that our verification processes are legally compliant and ethically sound.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3">
                                        <div class="card border-light bg-transparent text-center">
                                            <i class="fa fa-solid fa-money-bill fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                            <div class="card-body blockquote">
                                                <h3>Customization</h3>
                                                <p class="card-text" style={{fontSize:"20px"}}>We understand that every client has unique demands. That&#39;s why we offer
                                                    customizable verification solutions tailored to meet your specific needs.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-3">
                                        <div class="card border-light bg-transparent text-center">
                                            <i class="fa fa-solid fa-clock fa-3x card-img-top rounded-circle" aria-hidden="true"></i>
                                            <div class="card-body blockquote">
                                                <h3>Technology-Driven</h3>
                                                <p class="card-text" style={{fontSize:"20px"}}>We leverage cutting-edge technology and advanced tools to streamline our
                                                    processes, enhance accuracy, and deliver results in a timely manner.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>

                </div>
            </div>

    </div>
  )
}

export default Whyis