import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Service from './components/Service';
import Career from './components/Career';
import Contact from './components/Contact';
import Whyis from './components/Whyis';
import Testimonial from './components/Testimonial';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <div className='sticky-top'>
          <Navbar />
        </div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/whyis" element={<Whyis />} />
          <Route exact path="/client" element={<Career />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/testimonial" element={<Testimonial />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
