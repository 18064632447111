import React from 'react';
import './FormVideo.css'
const FormVideo = () => {
    return (
        <div>
            <form className="custom-form p-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group" style={{ textAlign: "left" }}>
                            <label htmlFor="inputEmail4" className='p-2' style={{ color: "white" }}>Average Hiring</label>
                            <input type="number" className="form-control transparent-input" id="hiringNumber" placeholder="Average monthly hiring" />
                        </div>
                        <div className="form-group" style={{ textAlign: "left" }}>
                            <label htmlFor="inputPassword4" className='p-2' style={{ color: "white" }} >First Name</label>
                            <input type="text" className="form-control transparent-input" id="firstName" placeholder="First Name" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group" style={{ textAlign: "left" }}>
                            <label htmlFor="inputAddress" className='p-2' style={{ color: "white" }}>Phone Number</label>
                            <input type="tel" className="form-control transparent-input" id="inputAddress" placeholder="Phone Number" />
                        </div>
                        <div className="form-group" style={{ textAlign: "left" }}>
                            <label htmlFor="inputAddress2" className='p-2' style={{ color: "white" }} >Last Name</label>
                            <input type="text" className="form-control transparent-input" id="lastName" placeholder="Last name" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group" style={{ textAlign: "left" }}>
                            <label htmlFor="inputCity" className='p-2' style={{ color: "white" }} >Email</label>
                            <input type="email" className="form-control transparent-input" id="inputCity" placeholder="Email" />
                        </div>
                        <div className="form-group" style={{ textAlign: "left" }}>
                            <label htmlFor="inputZip" className='p-2' style={{ color: "white" }}>Company Name</label>
                            <input type="text" className="form-control transparent-input" placeholder="Company Name" />
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-primary custom-btn">Submit</button>
            </form>
        </div>
    );
}

export default FormVideo;
