import React from 'react';
import Logo from './Logo2.png';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap'; // Import Bootstrap Navbar components

const Navbar = () => {
    const navItemStyle = {
       padding: "20px",
       cursor: 'pointer',
    };
    const navbarStyle = {
        backgroundColor: 'rgba(255, 255, 255, 1)', // Adjust the alpha value for the desired opacity
        maxHeight: '80px', // Reduced height
        zIndex: '999', // Set a higher z-index to ensure navbar appears above content
    };

    return (
        <div>
            <BootstrapNavbar expand="lg" variant="light" style={navbarStyle}>
                <div className="container-fluid">
                    <Link to='/' className='px-2'><img src={Logo} alt="logo" style={{ height: "60px", paddingRight: "20px" }} /></Link>
                    <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
                    <BootstrapNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mb-2 mb-lg-0">
                            <Nav.Link className="nav-link active" style={navItemStyle} as={Link} to="about" smooth={true} duration={100}>Home</Nav.Link>
                            <Nav.Link className="nav-link active" style={navItemStyle} as={Link} to="about" smooth={true} duration={100}>About</Nav.Link>
                            <Nav.Link className="nav-link active" style={navItemStyle} as={Link} to="whyis" smooth={true} duration={100}>Why Choose Us</Nav.Link>
                            <Nav.Link className="nav-link active" style={navItemStyle} as={Link} to="service" smooth={true} duration={100}>Service</Nav.Link>
                            <Nav.Link className="nav-link active" style={navItemStyle} as={Link} to="testimonial" smooth={true} duration={100}>Clients</Nav.Link>
                            <Nav.Link className="nav-link active" style={navItemStyle} as={Link} to="industry" smooth={true} duration={100}>Contact Us</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href="https://www.facebook.com/" className="me-4" ><i className="fab fa-facebook-f"></i></Nav.Link>
                            <Nav.Link href="https://www.instagram.com/" className="me-4" ><i className="fab fa-instagram"></i></Nav.Link>
                            <Nav.Link href="https://www.linkedin.com/" ><i className="fab fa-linkedin"></i></Nav.Link>
                        </Nav>
                    </BootstrapNavbar.Collapse>
                </div>
            </BootstrapNavbar>
        </div>
    );
};

export default Navbar;
